import type { PetUnderwriterType } from "spot-types/entities/PetQuote";
import type { OutageId } from "spot-types/events/OutageEvent";

export class PublicConfig {
    // ===============================
    //          GLOBAL VALUES
    // ===============================

    // Spot
    static ENVIRONMENT: string = process.env.NEXT_PUBLIC_ENVIRONMENT ?? "development";
    static DEV_ENV = PublicConfig.ENVIRONMENT === "development";
    static CANONICAL_URL_ROOT = process.env.NEXT_PUBLIC_CANONICAL_URL_ROOT || "http://localhost:3000";
    static SPOT_API_ROOT_URL = process.env.NEXT_PUBLIC_SPOT_API_URL ?? "https://dev-api.spotpet.com";
    static UNLIMITED_ANNUAL_LIMIT_VALUE = process.env.NEXT_PUBLIC_UNLIMITED_ANNUAL_LIMIT_VALUE !== undefined ? Number(process.env.NEXT_PUBLIC_UNLIMITED_ANNUAL_LIMIT_VALUE) : -1;
    static BASE_PATH = (process.env.NEXT_PUBLIC_BASE_PATH ?? "").trim();
    static MEMBER_LOGIN_URL: string = process.env.NEXT_PUBLIC_MEMBER_LOGIN_URL ?? "https://customer.spotpetins.com/portal/#/login";

    // Builder.io
    static BUILDER_IO_PUBLIC_API_KEY = process.env.NEXT_PUBLIC_BUILDER_IO_PUBLIC_API_KEY ?? "e660b516b60c4c63a4b427bc0e68aa64";

    // Castle.io
    static CASTLE_IO_PUBLIC_API_KEY = process.env.NEXT_PUBLIC_CASTLE_API_KEY ?? "pk_Bzq3e34hZibxyGtyqZax7GRVFhN6Essn";

    // DataDog
    static DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? "pub722e1d5ae91c43c471a840b4c4fe1748";
    static DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? "0772eb70-1279-4f61-95b8-10e164fd0f25";
    static DATADOG_APPLICATION_VERSION = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_VERSION;
    static DATADOG_SESSION_SAMPLE_RATE = Number(process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE ?? 100);
    static DATADOG_SESSION_RECORD_RATE = Number(process.env.NEXT_PUBLIC_DATADOG_SESSION_RECORD_RATE ?? 0);

    // Intercom
    static INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? "y5w7k8ms";

    // VWO
    static VWO_ACCOUNT_ID = process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID; // if undefined VWO will not be loaded
    static VWO_SETTINGS_TOLERANCE = process.env.NEXT_PUBLIC_VWO_SETTINGS_TOLERANCE !== undefined ? Number(process.env.NEXT_PUBLIC_VWO_SETTINGS_TOLERANCE) : 2000;

    // Max number of minutes to wait before changing the user's default 'return to quote' step
    static MAX_RETURN_TO_QUOTE_MINUTES = process.env.NEXT_PUBLIC_MAX_RETURN_TO_QUOTE_MINUTES !== undefined ? Number(process.env.NEXT_PUBLIC_MAX_RETURN_TO_QUOTE_MINUTES) : 60;

    // Refer a friend
    static IS_REFER_A_FRIEND_ENABLED = process.env.NEXT_PUBLIC_IS_REFER_A_FRIEND_ENABLED !== undefined ? Number(process.env.NEXT_PUBLIC_IS_REFER_A_FRIEND_ENABLED) === 1 : false;

    // Stripe
    static STRIPE_PUBLISHABLE_KEY =
        process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ?? "pk_test_51PgEROKCyWsnZgSKM9mevw1JCxqJameQ3aq45qOqQrDHPe3Dw6xeUJ1PeW3uJ21edzDqQ40ktsW6Gbwr5B4TCPjI00Nl9gLt3i";
    // Feature Flags
    static TAKEOVER_PROVISION_PCODES = !!process.env.NEXT_PUBLIC_TAKEOVER_PROVISION_PCODES ? JSON.parse(process.env.NEXT_PUBLIC_TAKEOVER_PROVISION_PCODES) : ["spot_takeovertest"];

    // Statsig
    static STATSIG_CLIENT_KEY = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY ?? "client-VJfrysoIAnI1eXya73C5ION7PboOVkjGqYjhYneSnkW";
    static STATSIG_LAYER_ID_MAIN = process.env.NEXT_PUBLIC_STATSIG_LAYER_ID_MAIN ?? "forms-main";

    // ===============================
    // FORM-SPECIFIC VALUES BELOW HERE
    // ===============================

    static PTZ_US = {
        // Spot
        FORM_ID: "quote-ptz-us",
        LOCAL_STORAGE_QUOTE_ID: "quoteIdPtzUs",
        UNDERWRITER: (process.env.NEXT_PUBLIC_PTZ_US_UNDERWRITER as PetUnderwriterType) ?? "ptz-us",
        SPOT_MARKETING_URL: "https://spotpet.com",
        QUOTE_ROOT_URL: PublicConfig.ENVIRONMENT === "production" ? "https://quote.spotpet.com" : "https://quote.spotpet.dev",
        SPOT_PERKS_PATH: "/spot-perks-2",
        SAMPLE_POLICY_PATH: "/spot-sample-policy.pdf",
        MEMBER_CENTER_PATH: "/member-center",
        UNDERWRITER_API_URL: process.env.NEXT_PUBLIC_PTZ_US_UNDERWRITER_API_URL ?? "https://qaservices.hartvillegroup.com/quoting",
        DEFAULT_ANNUAL_LIMIT: process.env.NEXT_PUBLIC_PTZ_US_DEFAULT_ANNUAL_LIMIT !== undefined ? Number(process.env.NEXT_PUBLIC_PTZ_US_DEFAULT_ANNUAL_LIMIT) : 2500,
        DEFAULT_ANNUAL_DEDUCTIBLE: process.env.NEXT_PUBLIC_PTZ_US_DEFAULT_ANNUAL_DEDUCTIBLE !== undefined ? Number(process.env.NEXT_PUBLIC_PTZ_US_DEFAULT_ANNUAL_DEDUCTIBLE) : 500,
        DEFAULT_REIMBURSMENT_RATE: process.env.NEXT_PUBLIC_PTZ_US_DEFAULT_REIMBURSMENT_RATE !== undefined ? Number(process.env.NEXT_PUBLIC_PTZ_US_DEFAULT_REIMBURSMENT_RATE) : 70,
        ACTIVE_OUTAGES: process.env.NEXT_PUBLIC_PTZ_US_ACTIVE_OUTAGES !== undefined ? (JSON.parse(process.env.NEXT_PUBLIC_PTZ_US_ACTIVE_OUTAGES) as OutageId[]) : [],

        // Segment
        SEGMENT_WEB_WRITE_KEY: process.env.NEXT_PUBLIC_PTZ_US_SEGMENT_WEB_WRITE_KEY ?? "2hCgEFEOgdrFJGOtwTFSivOjm2jiaUJq",

        //Amazon
        VALID_GIFTCARD_STATES_USF: !!process.env.NEXT_PUBLIC_VALID_GIFTCARD_STATES_USF ? (JSON.parse(process.env.NEXT_PUBLIC_VALID_GIFTCARD_STATES_USF) as string[]) : [],
        VALID_GIFTCARD_STATES_IAIC: !!process.env.NEXT_PUBLIC_VALID_GIFTCARD_STATES_IAIC ? (JSON.parse(process.env.NEXT_PUBLIC_VALID_GIFTCARD_STATES_IAIC) as string[]) : [],
        /// V1 is default langauge. Adding a state to this makes V2 verbiage appear for that state
        GIFTCARD_VERBIAGE_IAIC_V2: !!process.env.NEXT_PUBLIC_GIFTCARD_VERBIAGE_IAIC_V2
            ? (JSON.parse(process.env.NEXT_PUBLIC_GIFTCARD_VERBIAGE_IAIC_V2) as string[])
            : ["AR", "AZ", "FL", "ID", "IN", "ME", "NJ", "NM", "OK", "OR", "MT"],
        /// V3 language appears for those states
        GIFTCARD_VERBIAGE_IAIC_V3: !!process.env.NEXT_PUBLIC_GIFTCARD_VERBIAGE_IAIC_V3
            ? (JSON.parse(process.env.NEXT_PUBLIC_GIFTCARD_VERBIAGE_IAIC_V3) as string[])
            : ["GA", "KY", "MN", "MS", "NH", "NV", "OH", "PA", "WY"],

        // Model Law
        MODEL_LAW_STATES: !!process.env.NEXT_PUBLIC_MODEL_LAW_STATES ? JSON.parse(process.env.NEXT_PUBLIC_MODEL_LAW_STATES) : ["DE", "ME", "MS", "NE", "NH", "WA", "LA"],
        SEGMENT_CDN_URL: process.env.NEXT_PUBLIC_SEGMENT_CDN_URL ?? "https://analytics.spotpet.com",
        SEGMENT_API_URL: process.env.NEXT_PUBLIC_SEGMENT_API_URL ?? "segment.spotpet.com/v1",

        // Stripe, payment processor
        STRIPE_CONNECTED_ACCOUNT_ID: process.env.NEXT_PUBLIC_PTZ_US_STRIPE_CONNECTED_ACCOUNT_ID ?? "",
        THIRD_PARTY_PAYMENT_PROCESSOR: process.env.NEXT_PUBLIC_PTZ_US_THIRD_PARTY_PAYMENT_PROCESSOR ?? ""
    };

    static PTZ_CA = {
        // Spot
        FORM_ID: "quote-ptz-ca",
        UNDERWRITER: (process.env.NEXT_PUBLIC_PTZ_CA_UNDERWRITER as PetUnderwriterType) ?? "ptz-ca",
        SPOT_MARKETING_URL: "https://spotpetinsurance.ca",
        QUOTE_ROOT_URL: PublicConfig.ENVIRONMENT === "production" ? "https://quote.spotpetinsurance.ca" : "https://dev-quote.spotpetinsurance.ca",
        SAMPLE_POLICY_PATH: "/sample-policy",
        DEFAULT_ANNUAL_LIMIT: process.env.NEXT_PUBLIC_PTZ_US_DEFAULT_ANNUAL_LIMIT !== undefined ? Number(process.env.NEXT_PUBLIC_PTZ_CA_DEFAULT_ANNUAL_LIMIT) : 5000,
        LOCAL_STORAGE_QUOTE_ID: "quoteIdPtzCa",

        // Segment
        SEGMENT_WEB_WRITE_KEY: process.env.NEXT_PUBLIC_PTZ_CA_SEGMENT_WEB_WRITE_KEY ?? "FoXOH1vYoL92VYtcjCoU0Fy58HxAtE2z",
        // TODO: we need to move this to a CA URL
        SEGMENT_CDN_URL: process.env.NEXT_PUBLIC_SEGMENT_CDN_URL ?? "https://analytics.spotpet.com",
        SEGMENT_API_URL: process.env.NEXT_PUBLIC_SEGMENT_API_URL ?? "segment.spotpet.com/v1"
    };
}
